import * as ReactDOM from 'react-dom/client';
import Router from '@/router';
import Cookies from 'js-cookie';

import { getMainDomain } from './tools/common';

import './index.css';

const domain = getMainDomain();
[
  'token',
  'siteId',
  'fenxiaoToken',
  'miaohuoToken',
  'liguanjia_member_token',
  'liguanjia_supplier_token',
  'liguanjia_card_token',
  'liguanjia_member_token',
].forEach((tokenKey: string) => {
  Cookies.remove(tokenKey);
  Cookies.remove(tokenKey, { domain });
});

const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container!);

root.render(<Router />);
